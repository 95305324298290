import React, { FC } from "react";

import { Emoji } from "types/shared";

type Props = {
  selectedEmoji: Emoji;
};

const Header: FC<Props> = ({ selectedEmoji }) => {
  return (
    <header role="banner">
      <h1>
        <span role="img" aria-label="Clapping Emoji">
          {selectedEmoji.value}
        </span>
      </h1>
    </header>
  );
};

export default Header;
