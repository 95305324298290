import React, { FC } from "react";

export const Footer: FC = () => (
  <div className="footer">
    <p>
      Like so many other amazing things in this world, the entire concept of the
      emphasis clap comes from{" "}
      <a href="https://www.youtube.com/watch?v=34PjKtcVhVE">Black women</a>.
      They have been doing this long before Robin Thede introduced it to a
      broader audience on television and like most Black culture it became
      popular culture. If you get any value out of this little site consider
      paying it forward by shopping at a local Black-owned business.
    </p>

    <p>
      Made by <a href="https://twitter.com/graygilmore">@graygilmore</a> ❤️
    </p>
  </div>
);
