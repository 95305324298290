import React, { ChangeEvent, FC } from "react";

import { Emoji } from "types/shared";
import EmojiStyleOption from "./EmojiStyleOption";

type Props = {
  emojis: Emoji[];
  selectedEmoji: Emoji;
  updateBoringText: (text: string) => void;
  updateSelectedEmoji: (emoji: Emoji) => void;
};

const BoringForm: FC<Props> = ({
  emojis,
  selectedEmoji,
  updateBoringText,
  updateSelectedEmoji,
}) => {
  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    updateBoringText(event.target.value);

  const emojiStyleOptions = emojis.map((emoji) => (
    <EmojiStyleOption
      key={emoji.name}
      emoji={emoji}
      selected={selectedEmoji.name === emoji.name}
      updateSelectedEmoji={updateSelectedEmoji}
    />
  ));

  return (
    <div className="input-wrapper">
      <textarea
        className="boring-👏less-input"
        placeholder="Put your super boring non clappy text here"
        rows={4}
        onChange={handleTextChange}
      />

      <div className="color-options">{emojiStyleOptions}</div>
    </div>
  );
};

export default BoringForm;
