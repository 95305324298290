import React, { FC, useEffect, useRef, useState } from 'react';

import { Emoji } from 'types/shared';

type Props = {
  selectedEmoji: Emoji;
  text: string;
}

const AmazingOutput: FC<Props> = ({ selectedEmoji, text }) => {
  const [copied, updateCopied] = useState(false);
  const [error, updateError] = useState<string | null>(null);

  useEffect(() => {
    updateCopied(false);
  }, [selectedEmoji, text]);

  const amazingTextRef = useRef<HTMLDivElement>(null);

  const copyToClipboard = () => {
    const windowSelection = window.getSelection();

    if (!windowSelection || amazingTextRef.current === null) {
      showError();
      return
    }

    // First we need to remove all active selections. This is required to fix
    // a bug in Chrome. https://github.com/w3c/selection-api/issues/80
    windowSelection.removeAllRanges();

    const range = document.createRange();
    range.selectNodeContents(amazingTextRef.current);
    windowSelection.addRange(range);

    try {
      document.execCommand('copy');
      updateCopied(true);
      updateError(null);
    } catch (err) {
      showError();
    }
  };

  const showError = () => {
    updateCopied(false);
    updateError('Unable to automatically copy the text to your clipboard');
  }

  const replaceSpaces = (text: string):string => {
    return text.replace(/\s+/g, ` ${selectedEmoji.value} `)
  }

  return (
    <div className="output-wrapper">
      <div
        className="amazing-👏ful-output"
        dangerouslySetInnerHTML={{ __html: replaceSpaces(text) }}
        ref={amazingTextRef}
      />

      <button
        className={`button-primary ${copied && 'is-copied'}`}
        onClick={copyToClipboard}
      >
        {copied ? 'Copied!' : 'Copy Text'}
      </button>
      {!!error && <p>{error}</p>}
    </div>
  );
};

export default AmazingOutput;
