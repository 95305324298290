import React, { FC, useState } from "react";

import { Emoji } from "types/shared";
import Header from "./components/Header";
import BoringForm from "./components/BoringForm";
import AmazingOutput from "./components/AmazingOutput";
import { Footer } from "components/Footer";
import "./clapclapclap.scss";

const App: FC = () => {
  const emojiStyles: Emoji[] = [
    { name: "neutral", value: "👏" },
    { name: "dark", value: "👏🏿" },
    { name: "mediumDark", value: "👏🏾" },
    { name: "medium", value: "👏🏽" },
    { name: "mediumLight", value: "👏🏼" },
    { name: "light", value: "👏🏻" },
  ];

  const [selectedEmoji, updateSelectedEmoji] = useState(emojiStyles[0]);
  const [boringText, updateBoringText] = useState("");

  return (
    <>
      <Header selectedEmoji={selectedEmoji} />

      <div className="main" role="main">
        <BoringForm
          emojis={emojiStyles}
          selectedEmoji={selectedEmoji}
          updateBoringText={updateBoringText}
          updateSelectedEmoji={updateSelectedEmoji}
        />
        <AmazingOutput text={boringText} selectedEmoji={selectedEmoji} />
      </div>

      <Footer />
    </>
  );
};

export default App;
