import React, { FC } from 'react';

import { Emoji } from 'types/shared';

type Props = {
  emoji: Emoji;
  selected: boolean;
  updateSelectedEmoji: (emoji: Emoji) => void;
}

const EmojiStyleOption: FC<Props> = ({ emoji, selected, updateSelectedEmoji }) => {
  const handleChange = () => updateSelectedEmoji(emoji);

  return (
    <>
      <input
        type="radio"
        name="color-option"
        id={emoji.name}
        value={emoji.value}
        checked={selected}
        onChange={handleChange}
      />
      <label className="color-option" htmlFor={emoji.name}>
        {emoji.value}
      </label>
    </>
  );
};

export default EmojiStyleOption;
